import { EquipmentReturnState } from "./types";

function ConfirmationForm(props: { currentState: EquipmentReturnState, setCurrentState: any }) {
  return (<>
    <div className="mb-5">
      <div className="alert alert-info">Please confirm all of the details below before continuing.</div>
    </div>

    <h2>Contact Details</h2>
    <p>Here are the contact details you provided. We may share these with the courier to arrange your collections.</p>
    <table className="table table-bordered mb-5">
      <tbody>
        <tr>
          <td><strong>First Name</strong></td>
          <td>{props.currentState.contactDetails_firstName}</td>
        </tr>
        <tr>
          <td><strong>Last Name</strong></td>
          <td>{props.currentState.contactDetails_lastName}</td>
        </tr>
        <tr>
          <td><strong>Email Address</strong></td>
          <td>{props.currentState.contactDetails_emailAddress}</td>
        </tr>
        <tr>
          <td><strong>Phone Number</strong></td>
          <td>+44{props.currentState.contactDetails_phoneNumber}</td>
        </tr>
      </tbody>
    </table>

    <div className="mb-5">
      {props.currentState.assets.some((a) => a.boxRequired) ? (<>
        <h2>Box Arrival &amp; Collection</h2>
        <p>Since you've indicated that <strong>you require a box</strong>, we will send a courier to drop off a box on <strong>{props.currentState.addressDetails_boxArrivalDate}</strong> and then another to collect your packed equipment on <strong>{props.currentState.addressDetails_collectionDate}</strong>.</p>
      </>) : (<>
        <h2>Collection</h2>
        <p>Since you've indicated that <strong>you do not require a box</strong>, we will simply send a courier to collect your equipment on <strong>{props.currentState.addressDetails_collectionDate}</strong>.</p>
      </>)}
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td><strong>Address Line 1</strong></td>
            <td>{props.currentState.addressDetails_line1}</td>
          </tr>
          <tr>
            <td><strong>Address Line 2</strong></td>
            <td>{props.currentState.addressDetails_line2}</td>
          </tr>
          <tr>
            <td><strong>Town/City</strong></td>
            <td>{props.currentState.addressDetails_townCity}</td>
          </tr>
          <tr>
            <td><strong>Postcode</strong></td>
            <td>{props.currentState.addressDetails_postcode}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>Your Assets</h2>
    <p>We use this just to double check that our inventory is correct. We may arrange multiple collections, which we will email you confirmation for within 3 days.</p>
    <div className="mb-5">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Asset Tag</th>
            <th>Type</th>
            <th>Dimensions (cm)</th>
            <th>Weight (kg)</th>
            <th>Box Required</th>
          </tr>
        </thead>
        <tbody>
          {props.currentState.assets.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center text-muted">You have not added any assets yet.</td>
            </tr>
          ) : (
            props.currentState.assets.map((asset) => {
              return <tr key={props.currentState.assets.indexOf(asset)}>
                <td>{asset.assetTag}</td>
                <td>{asset.type}</td>
                <td>{asset.width}x{asset.length}x{asset.height}cm</td>
                <td>{asset.weight}kg</td>
                <td>{asset.boxRequired ? 'Yes' : 'No'}</td>
              </tr>
            })
          )}
        </tbody>
      </table>
    </div>

    <h2>Something not right?</h2>
    <p>Click the "Go Back" button below to revise your answers.</p>
  </>);
}

export default ConfirmationForm;