import { useState } from 'react';
import AddressForm from './AddressForm';
import AssetForm from './AssetForm';
import Completed from './Completed';
import ConfirmationForm from './ConfirmationForm';
import ContactDetailsForm from './ContactDetailsForm';
import NotFound from './NotFound';
import { Asset, EquipmentReturnState } from './types';

function App() {
  const pageActions: any = {
    'contact-details': {
      back: undefined,
      continue: () => {
        if (![
          currentState.contactDetails_firstName,
          currentState.contactDetails_lastName,
          currentState.contactDetails_emailAddress,
          currentState.contactDetails_phoneNumber
        ].every((v) => v)) {
          alert('Please ensure you fill in all of your contact details.');
          return;
        }

        if (currentState.contactDetails_phoneNumber?.toString().length !== 10) {
          alert('Please check that you have entered the correct phone number. We expect 10 digits (no leading 0).');
          return;
        }

        setCurrentView('address');
      }
    },
    'address': {
      back: () => { setCurrentView('contact-details'); },
      continue: () => {
        if (![
          currentState.addressDetails_line1,
          currentState.addressDetails_townCity,
          currentState.addressDetails_postcode,
          currentState.addressDetails_boxArrivalDate,
          currentState.addressDetails_collectionDate
        ].every((v) => v)) {
          alert('Please ensure you fill in all of your address details, including both dates for collection purposes.');
          return;
        }

        setCurrentView('assets');
      }
    },
    'assets': {
      back: () => { setCurrentView('address'); },
      continue: () => {
        if (currentState.assets.length === 0) {
          alert('Please ensure you have added all of your company assets.');
          return;
        }
        setCurrentView('confirmation');
      }
    },
    'confirmation': {
      back: () => { setCurrentView('assets'); },
      continue: () => {
        setCurrentView('completed');
      }
    },
    'completed': {
      back: undefined,//() => setCurrentView('confirmation'),
      continue: undefined
    }
  };

  const [currentView, setCurrentView] = useState<string>('contact-details');
  const [currentState, setCurrentState] = useState<EquipmentReturnState>({
    'contactDetails_firstName': undefined,
    'contactDetails_lastName': undefined,
    'contactDetails_emailAddress': undefined,
    'contactDetails_phoneNumber': undefined,

    'addressDetails_line1': undefined,
    'addressDetails_line2': undefined,
    'addressDetails_townCity': undefined,
    'addressDetails_postcode': undefined,
    'addressDetails_boxArrivalDate': undefined,
    'addressDetails_collectionDate': undefined,

    assets: new Array<Asset>()
  });

  function renderCurrentView() {
    switch (currentView) {
      case 'contact-details':
        return <ContactDetailsForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'address':
        return <AddressForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'assets':
        return <AssetForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'confirmation':
        return <ConfirmationForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'completed':
        return <Completed currentState={currentState} setCurrentState={setCurrentState} goBack={() => setCurrentView('confirmation')} />;
      default:
        return <NotFound />;
    }
  }

  return (
    <div className="container-fluid my-5">
      <div className="row align-items-center justify-content-center mb-2">
        <div className="col-11 col-md-8 col-lg-7">
          <h1>Equipment Returns</h1>
          <p className="lead">Let us know how you'd like us to handle your equipment returns.</p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center mb-5">
        <div className="col-11 col-md-8 col-lg-7">
          <nav className="nav nav-pills flex-column flex-sm-row">
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='contact-details' ? 'active' : 'disabled'}`}>1. Contact Details</span>
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='address' ? 'active' : 'disabled'}`}>2. Your Address</span>
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='assets' ? 'active' : 'disabled'}`}>3. Your Assets</span>
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='confirmation' ? 'active' : 'disabled'}`}>4. Confirmation</span>
          </nav>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-11 col-md-8 col-lg-7">
          {renderCurrentView()}

          <div className="text-end mt-5">
            {pageActions[currentView].back ? (
              <button type="button" className="btn btn-dark float-start" onClick={() => pageActions[currentView].back()}>Go Back</button>
            ) : <></>}

            {pageActions[currentView].continue ? (
              <button type="button" className="btn btn-primary" onClick={() => pageActions[currentView].continue()}>Continue</button>
            ) : <></>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
