import { useState } from "react";
import { Asset, EquipmentReturnState } from "./types";

function AssetForm(props: { currentState: EquipmentReturnState, setCurrentState: any }) {
  const [formAssetTag, setFormAssetTag] = useState<string>('');
  const [formAssetType, setFormAssetType] = useState<string>('Laptop');
  const [formWidth, setFormWidth] = useState<number>(10);
  const [formLength, setFormLength] = useState<number>(10);
  const [formHeight, setFormHeight] = useState<number>(10);
  const [formWeight, setFormWeight] = useState<number>(1);
  const [formBoxRequired, setFormBoxRequired] = useState<boolean | undefined>(true);

  const assetTypes = [
    {
      value: 'Laptop',
      label: 'Laptop'
    },
    {
      value: 'Tablet',
      label: 'Tablet'
    },
    {
      value: 'Display',
      label: 'Display'
    },
    {
      value: 'Peripheral',
      label: 'Mouse / Keyboard'
    },
    {
      value: 'CableOrAdapter',
      label: 'Cable / Adapter'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ];

  const addNewAsset = () => {
    if (![
      formAssetType,
      formWidth,
      formLength,
      formHeight,
      formWeight
    ].every((v) => v)) {
      alert('Please ensure all fields are filled in correctly.');
      return;
    }

    if (formBoxRequired === undefined) {
      alert('Please ensure all fields are filled in correctly.');
      return;
    }

    props.setCurrentState({
      ...props.currentState,
      assets: [
        ...props.currentState.assets,
        {
          assetTag: formAssetTag || formAssetTag,
          type: formAssetType,
          width: formWidth,
          length: formLength,
          height: formHeight,
          weight: formWeight,
          boxRequired: formBoxRequired
        }
      ]
    });

    setFormAssetTag('');
    setFormAssetType('Laptop');
    setFormWidth(10);
    setFormLength(10);
    setFormHeight(10);
    setFormWeight(1);
    setFormBoxRequired(true);
  };

  const editAsset = (asset: Asset) => {
    setFormAssetTag(asset.assetTag);
    setFormAssetType(asset.type);
    setFormWidth(asset.width);
    setFormLength(asset.length);
    setFormHeight(asset.height);
    setFormWeight(asset.weight);
    setFormBoxRequired(asset.boxRequired);
    removeAsset(asset);
  };

  const removeAsset = (asset: Asset) => {
    const currentAssets = props.currentState.assets;
    props.setCurrentState({
      ...props.currentState,
      assets: currentAssets.filter((_asset) => _asset !== asset)
    });
  };
  
  return (<>
    <div className="card card-body mb-4">
      <h3>Add New Asset</h3>
      <div className="mb-3">
        <label htmlFor="assetTag" className="form-label">
          Asset Tag, Serial Number, or Service Tag<br />
          <em>This is usually found on the rear of your device but may be positioned elsewhere.<br />You may see the letters "S/N" or "S/T" next to it, or it may be on a sticker.</em></label>
        <input type="text" className="form-control" id="assetTag" value={formAssetTag} placeholder="SG-ASSET-123" onChange={(e) => { setFormAssetTag(e.target.value); }} />
      </div>

      <div className="mb-3">
        <label htmlFor="assetType" className="form-label">Asset Type</label>
        <select className="form-control" id="assetType" onChange={(e) => { setFormAssetType(e.target.value); }} value={formAssetType}>
          {assetTypes.map((assetType) => {
            return <option key={assetTypes.indexOf(assetType)} value={assetType.value}>{assetType.label}</option>;
          })}
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="width" className="form-label">Width (cm)</label>
        <input type="number" className="form-control" id="width" value={formWidth} placeholder="Width (cm)" onChange={(e) => { setFormWidth(parseInt(e.target.value)); }} />
      </div>

      <div className="mb-3">
        <label htmlFor="length" className="form-label">Length (cm)</label>
        <input type="number" className="form-control" id="length" value={formLength} placeholder="Length (cm)" onChange={(e) => { setFormLength(parseInt(e.target.value)); }} />
      </div>

      <div className="mb-3">
        <label htmlFor="height" className="form-label">Height (cm)</label>
        <input type="number" className="form-control" id="height" value={formHeight} placeholder="Height (cm)" onChange={(e) => { setFormHeight(parseInt(e.target.value)); }} />
      </div>

      <div className="mb-3">
        <label htmlFor="weight" className="form-label">Weight (kg)</label>
        <input type="number" className="form-control" id="weight" value={formWeight} placeholder="Weight (kg)" onChange={(e) => { setFormWeight(parseInt(e.target.value)); }} />
      </div>

      <div className="mb-3">
        <label htmlFor="boxRequired" className="form-label">Do you need us to send you a box for this asset?</label>
        <select className="form-control" id="boxRequired" onChange={(e) => { setFormBoxRequired(e.target.value === 'box_required'); }} value={formBoxRequired ? 'box_required' : 'box_not_required'}>
          <option value="box_required">Yes, please send me a box.</option>
          <option value="box_not_required">No, I have my own box and packing materials.</option>
        </select>
      </div>

      <div className="text-end">
        <button type="button" className="btn btn-primary" onClick={addNewAsset}>Add Asset</button>
      </div>
    </div>

    <div className="mb-3">
      <div className="alert alert-info">Please ensure all of your company devices are accounted for below.</div>
    </div>

    <div className="mb-3">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Asset Tag</th>
            <th>Type</th>
            <th>Dimensions (cm)</th>
            <th>Weight (kg)</th>
            <th>Box Required</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.currentState.assets.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center text-muted">You have not added any assets yet.</td>
            </tr>
          ) : (
            props.currentState.assets.map((asset) => {
              return <tr key={props.currentState.assets.indexOf(asset)}>
                <td>{asset.assetTag}</td>
                <td>{asset.type}</td>
                <td>{asset.width}x{asset.length}x{asset.height}cm</td>
                <td>{asset.weight}kg</td>
                <td>{asset.boxRequired ? 'Yes' : 'No'}</td>
                <td>
                  <a href="#" onClick={() => { editAsset(asset); }}>Edit</a>
                  &nbsp;/&nbsp;
                  <a href="#" onClick={() => { removeAsset(asset); }}>Remove</a>
                </td>
              </tr>
            })
          )}
        </tbody>
      </table>
    </div>
  </>);
}

export default AssetForm;