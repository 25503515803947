import { useEffect, useState } from "react";
import { EquipmentReturnState } from "./types";

function AddressForm(props: { currentState: EquipmentReturnState, setCurrentState: any }) {
  const [minimumBoxArrivalDate] = useState<Date>(new Date(new Date().getTime()+(7*24*3600*1000)));
  const [minimumCollectionDate, setMinimumCollectionDate] = useState<Date>(new Date(new Date().getTime()+(8*24*3600*1000)));

  useEffect(() => {
    if (!props.currentState.addressDetails_boxArrivalDate) {
      props.setCurrentState({
        ...props.currentState,
        'addressDetails_boxArrivalDate': minimumBoxArrivalDate.toISOString().split('T')[0]
      });
      return;
    }

    console.log('currentState', props.currentState);
    const boxArrivalDate = new Date(Date.parse(props.currentState.addressDetails_boxArrivalDate));

    const minCollectionDate = new Date(boxArrivalDate.getTime()+(1*24*3600*1000));
    setMinimumCollectionDate(minCollectionDate);

    if (
      !props.currentState.addressDetails_collectionDate
      || (props.currentState.addressDetails_collectionDate && new Date(Date.parse(props.currentState.addressDetails_collectionDate)) < minCollectionDate)
    ) {
      props.setCurrentState({
        ...props.currentState,
        'addressDetails_collectionDate': minCollectionDate.toISOString().split('T')[0]
      });
    }
  }, [
    minimumBoxArrivalDate,
    props
  ]);

  return (<>
    <div className="mb-3">
      <label htmlFor="addressLine1" className="form-label">Address Line 1</label>
      <input type="text" className="form-control" id="addressLine1" placeholder="10 Downing Street" value={props.currentState.addressDetails_line1} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'addressDetails_line1': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
      <input type="text" className="form-control" id="addressLine2" placeholder="" value={props.currentState.addressDetails_line2} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'addressDetails_line2': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="townCity" className="form-label">Town/City</label>
      <input type="text" className="form-control" id="townCity" placeholder="London" value={props.currentState.addressDetails_townCity} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'addressDetails_townCity': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="postcode" className="form-label">Postcode</label>
      <input type="text" className="form-control" id="postcode" placeholder="SW1A 2AA" value={props.currentState.addressDetails_postcode} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'addressDetails_postcode': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="boxArrivalDate" className="form-label">
        If we need to send you boxes (determined on the next step), when would you like them to arrive?<br />
        <strong>Please select at least a week in the future. Weekends are not available.</strong>
      </label>
      <input type="date" className="form-control" id="boxArrivalDate" min={minimumBoxArrivalDate.toISOString().split('T')[0]} value={props.currentState.addressDetails_boxArrivalDate} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'addressDetails_boxArrivalDate': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="collectionDate" className="form-label">
        Which date works best for a collection?<br />
        <strong>Please select at least 1 day after the date above. Weekends are not available.</strong>
      </label>
      <input type="date" className="form-control" id="collectionDate" min={minimumCollectionDate.toISOString().split('T')[0]} value={props.currentState.addressDetails_collectionDate} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'addressDetails_collectionDate': e.target.value
        })
      }} />
    </div>
  </>);
}

export default AddressForm;